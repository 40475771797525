<template>
	<div>
		<Breadcrumb></Breadcrumb>
		<el-row :gutter="20" type="flex">
		      <el-col :span="24">
		        <el-card class="mt-2">
		          <h4 class="fs_18 font-weight-semibold m-0 text-000 mb-3">会员信息</h4>
		          <div class="w-100 d-flex align-items-center flex-wrap">
					  <el-divider content-position="left">基本信息</el-divider>
					  <el-row class="w-100 flex align-center mb-2">
						  <el-col :span="12">
							  <div class="w-100 flex align-center">
								<div class="flex align-center opacity-60" style="width: 100px;">
									会员名称：
								</div>
								<div class="flex-1">
									{{data.username || ''}}
								</div>
							  </div>
						  </el-col>
						  <el-col :span="12">
							 <div class="w-100 flex align-center">
								<div class="flex align-center opacity-60" style="width: 100px;">
									会员昵称：
								</div>
								<div class="flex-1">
									{{data.nickname || ''}}
								</div>
							 </div>
						  </el-col>
					  </el-row>
                      <el-row class="w-100 flex align-center mb-2">
						  <el-col :span="12">
							  <div class="w-100 flex align-center">
								<div class="flex align-center opacity-60" style="width: 100px;">
									会员电话：
								</div>
								<div class="flex-1">
									{{data.mobile || ''}}
								</div>
							  </div>
						  </el-col>
						  <el-col :span="12">
							 <div class="w-100 flex align-center">
								<div class="flex align-center opacity-60" style="width: 100px;">
									会员级别：
								</div>
								<div class="flex-1">
									{{data.grade_name || ''}}
								</div>
							 </div>
						  </el-col>
                      </el-row>
                      <el-row class="w-100 flex align-center mb-2">
						  <el-col :span="12">
							  <div class="w-100 flex align-center">
								<div class="flex align-center opacity-60" style="width: 100px;">
									会员性别：
								</div>
								<div class="flex-1">
									<span v-if="data.gender == 1">男</span>
									<span v-else-if="data.gender == 2">女</span>
									<span v-else-if="data.gender == 3">未知</span>
								</div>
							  </div>
						  </el-col>
						  <el-col :span="12">
							 <div class="w-100 flex align-center">
								<div class="flex align-center opacity-60" style="width: 100px;">
									会员状态：
								</div>
								<div class="flex-1">
									<el-tag v-if="data.status === 1" size="mini" type="success">正常</el-tag>
									<el-tag v-if="data.status === 2" size="mini" type="danger">停用</el-tag>
								</div>
							 </div>
						  </el-col>
                      </el-row>
					  <el-row class="w-100 flex align-center mb-2">
						  <el-col :span="12">
							  <div class="w-100 flex align-center">
								<div class="flex align-center opacity-60" style="width: 100px;">
									销量：
								</div>
								<div class="flex-1">
									{{data.nums || '0'}}
								</div>
							  </div>
						  </el-col>
						  <el-col :span="12">
							 <div class="w-100 flex align-center">
								<div class="flex align-center opacity-60" style="width: 100px;">
									积分：
								</div>
								<div class="flex-1">
									{{data.balance || '0'}}
								</div>
							 </div>
						  </el-col>
					  </el-row>
					  <el-row class="w-100 flex align-center mb-2" v-if="data.pid != 0">
						  <el-col :span="12">
							  <div class="w-100 flex align-center">
								<div class="flex align-center opacity-60" style="width: 100px;">
									上级名称：
								</div>
								<div class="flex-1">
									{{data.pname || ''}}
								</div>
							  </div>
						  </el-col>
						  <el-col :span="12">
							 <div class="w-100 flex align-center">
								<div class="flex align-center opacity-60" style="width: 100px;">
									上级电话：
								</div>
								<div class="flex-1">
									{{data.pmobile || ''}}
								</div>
							 </div>
						  </el-col>
					  </el-row>
					  <el-row class="w-100 flex align-center mb-2">
						  <el-col :span="12">
							  <div class="w-100 flex align-center">
								<div class="flex align-center opacity-60" style="width: 100px;">
									会员头像：
								</div>
								<div class="flex-1">
									<img :src="data.avatar" style="width: 30px;height: 30px;" @click="viewImage(data,'avatar')" v-if="data.avatar">
									<img src="@/assets/images/empty.png" style="width: 30px;height: 30px;" v-else>
								</div>
							  </div>
						  </el-col>
						  <el-col :span="12">
							 <div class="w-100 flex align-center">
								<div class="flex align-center opacity-60" style="width: 100px;">
									备注信息：
								</div>
								<div class="flex-1">
									{{data.remarks || ''}}
								</div>
							 </div>
						  </el-col>
					  </el-row>
					  <el-row class="w-100 flex align-center mb-2">
						  <el-col :span="12">
							  <div class="w-100 flex align-center">
								<div class="flex align-center opacity-60" style="width: 100px;">
									注册时间：
								</div>
								<div class="flex-1">
									{{data.ctime || ''}}
								</div>
							  </div>
						  </el-col>
						  <el-col :span="12">
							  
						  </el-col>
					  </el-row>
		          </div>
		        </el-card>
		      </el-col>
		</el-row>
	</div>
</template>

<script>
	import common from "@/common/mixins/common.js"
	import Breadcrumb from '@/components/Breadcrumb';
	export default {
		inject:['app'],
		mixins:[common],
		components: {
			Breadcrumb
		},
		data() {
			return {
				preUrl: 'user',
				autoRequest: false,
				queryForm: {
					page: 1,
					limit: 10,
					keyword: '',
				},
				id:'',
				data:{},
			}
		},
		mounted() {
			if(this.$route.query.id){
				this.id = this.$route.query.id
				this.getDetail()
			}
		},
		methods:{
			getDetail() {
			  this.axios.get(`/manage/${this.preUrl}/detail`, {
			    params: {
					id:this.id
				}
			  }).then(res => {
			    this.data = res.data
			  })
			},
		}
	}
</script>

<style>
</style>